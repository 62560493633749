import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VCard,
    { staticClass: "md-auto" },
    [
      _c(VCardTitle, { staticClass: "justify-center" }, [
        _c(
          "p",
          [
            _vm._v("Statistics "),
            _c(VIcon, { attrs: { large: "" } }, [_vm._v("mdi-chart-line")])
          ],
          1
        )
      ]),
      _c(
        VForm,
        {
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.onSubmit($event)
            }
          }
        },
        [
          _c(
            VRow,
            { staticClass: "mt-2" },
            [
              _c(
                VCol,
                { attrs: { cols: "3" } },
                [
                  _c(
                    VMenu,
                    {
                      attrs: {
                        "close-on-content-click": false,
                        "nudge-right": 40,
                        transition: "scale-transition",
                        "offset-y": "",
                        "min-width": "290px"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                VTextField,
                                _vm._g(
                                  {
                                    attrs: {
                                      outlined: "",
                                      dense: "",
                                      label: "Start date",
                                      "prepend-icon": "mdi-calendar",
                                      readonly: "",
                                      required: ""
                                    },
                                    model: {
                                      value: _vm.localStartDate,
                                      callback: function($$v) {
                                        _vm.localStartDate = $$v
                                      },
                                      expression: "localStartDate"
                                    }
                                  },
                                  on
                                )
                              )
                            ]
                          }
                        }
                      ]),
                      model: {
                        value: _vm.startMenu,
                        callback: function($$v) {
                          _vm.startMenu = $$v
                        },
                        expression: "startMenu"
                      }
                    },
                    [
                      _c(VDatePicker, {
                        attrs: {
                          small: "",
                          "allowed-dates": _vm.allowedStartDates
                        },
                        on: {
                          input: function($event) {
                            _vm.startMenu = false
                          }
                        },
                        model: {
                          value: _vm.localStartDate,
                          callback: function($$v) {
                            _vm.localStartDate = $$v
                          },
                          expression: "localStartDate"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                VCol,
                { attrs: { cols: "3" } },
                [
                  _c(
                    VMenu,
                    {
                      attrs: {
                        "close-on-content-click": false,
                        "nudge-right": 40,
                        transition: "scale-transition",
                        "offset-y": "",
                        "min-width": "290px"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                VTextField,
                                _vm._g(
                                  {
                                    attrs: {
                                      outlined: "",
                                      dense: "",
                                      label: "End date",
                                      "prepend-icon": "mdi-calendar",
                                      readonly: "",
                                      required: ""
                                    },
                                    model: {
                                      value: _vm.localEndDate,
                                      callback: function($$v) {
                                        _vm.localEndDate = $$v
                                      },
                                      expression: "localEndDate"
                                    }
                                  },
                                  on
                                )
                              )
                            ]
                          }
                        }
                      ]),
                      model: {
                        value: _vm.endMenu,
                        callback: function($$v) {
                          _vm.endMenu = $$v
                        },
                        expression: "endMenu"
                      }
                    },
                    [
                      _c(VDatePicker, {
                        attrs: {
                          small: "",
                          "allowed-dates": _vm.allowedEndDates
                        },
                        on: {
                          input: function($event) {
                            _vm.endMenu = false
                          }
                        },
                        model: {
                          value: _vm.localEndDate,
                          callback: function($$v) {
                            _vm.localEndDate = $$v
                          },
                          expression: "localEndDate"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                VCol,
                { attrs: { cols: "3" } },
                [
                  _c(VAutocomplete, {
                    attrs: {
                      outlined: "",
                      dense: "",
                      items: ["university", "graduate", "teacher", "trial"],
                      label: "Licence type",
                      "prepend-icon": "mdi-wallet-membership",
                      required: ""
                    },
                    model: {
                      value: _vm.localType,
                      callback: function($$v) {
                        _vm.localType = $$v
                      },
                      expression: "localType"
                    }
                  })
                ],
                1
              ),
              _c(
                VCol,
                { attrs: { cols: "1" } },
                [
                  _c(
                    VBtn,
                    {
                      attrs: {
                        outlined: "",
                        color: "primary",
                        loading: _vm.loadingBtn,
                        disabled: _vm.loadingBtn
                      },
                      on: {
                        click: function($event) {
                          return _vm.onSubmit()
                        }
                      }
                    },
                    [_vm._v(" Submit ")]
                  )
                ],
                1
              ),
              _c(
                VCol,
                { attrs: { cols: "2" } },
                [
                  _c(
                    VBtn,
                    {
                      attrs: {
                        outlined: "",
                        color: "primary",
                        disabled: !_vm.createdStatistics
                      },
                      on: {
                        click: function($event) {
                          return _vm.exportStatistics()
                        }
                      }
                    },
                    [_vm._v(" Export ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        [
          _c(VDataTable, {
            attrs: {
              headers: _vm.headers,
              items: _vm.statistics,
              search: _vm.search,
              "sort-by": _vm.sortBy,
              "sort-desc": _vm.desc
            },
            on: {
              "update:sortBy": function($event) {
                _vm.sortBy = $event
              },
              "update:sort-by": function($event) {
                _vm.sortBy = $event
              },
              "update:sortDesc": function($event) {
                _vm.desc = $event
              },
              "update:sort-desc": function($event) {
                _vm.desc = $event
              },
              "click:row": _vm.onRowClick
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }