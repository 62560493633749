import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VCard,
    { staticClass: "ma-5" },
    [
      _c(
        VTabs,
        {
          attrs: { centered: "", "icons-and-text": "" },
          model: {
            value: _vm.tab,
            callback: function($$v) {
              _vm.tab = $$v
            },
            expression: "tab"
          }
        },
        [
          _c(VTabsSlider),
          _c(VTab, { attrs: { href: "#roles" } }, [_vm._v(" Roles ")]),
          _c(VTab, { attrs: { href: "#distributors", disabled: "" } }, [
            _vm._v(" Distributors ")
          ])
        ],
        1
      ),
      _c(
        VTabsItems,
        {
          attrs: { align: "center" },
          model: {
            value: _vm.tab,
            callback: function($$v) {
              _vm.tab = $$v
            },
            expression: "tab"
          }
        },
        [
          _c(
            VTabItem,
            { attrs: { value: "roles" } },
            [
              _c(
                VCard,
                { attrs: { outlined: "", loading: _vm.loading } },
                [
                  _c(
                    VCardTitle,
                    [
                      _c(
                        VRow,
                        { attrs: { justify: "center" } },
                        [
                          _c(
                            VCol,
                            { attrs: { cols: "4" } },
                            [
                              _c(VAutocomplete, {
                                attrs: {
                                  dense: "",
                                  outlined: "",
                                  items: _vm.roles,
                                  "item-text": "id",
                                  "item-value": "id",
                                  "prepend-icon": "note-edit-outline",
                                  label: "Choose role"
                                },
                                model: {
                                  value: _vm.selectedRole,
                                  callback: function($$v) {
                                    _vm.selectedRole = $$v
                                  },
                                  expression: "selectedRole"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    VCardSubtitle,
                    [
                      _vm.selectedRole !== null
                        ? _c(
                            VMenu,
                            {
                              attrs: {
                                transition: "slide-y-transition",
                                "close-on-content-click": false,
                                bottom: ""
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var attrs = ref.attrs
                                      var on = ref.on
                                      return [
                                        _c(
                                          VBtn,
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass: "ma-4",
                                                attrs: {
                                                  color: "info",
                                                  outlined: ""
                                                }
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c(
                                              VIcon,
                                              {
                                                staticClass: "mr-3",
                                                attrs: { dark: "" }
                                              },
                                              [_vm._v("mdi-account-plus")]
                                            ),
                                            _vm._v(" Add user to role ")
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                501453414
                              )
                            },
                            [
                              _c(
                                VCard,
                                [
                                  _c(
                                    VCardText,
                                    [
                                      _c(VTextField, {
                                        attrs: {
                                          outlined: "",
                                          dense: "",
                                          "prepend-icon":
                                            "mdi-account-search-outline"
                                        },
                                        model: {
                                          value: _vm.selectedEmail,
                                          callback: function($$v) {
                                            _vm.selectedEmail = $$v
                                          },
                                          expression: "selectedEmail"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCardActions,
                                    [
                                      _c(VSpacer),
                                      _c(
                                        VBtn,
                                        {
                                          attrs: {
                                            outlined: "",
                                            color: "info",
                                            disabled:
                                              _vm.selectedEmail === null,
                                            loading: _vm.loading
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.addToRole()
                                            }
                                          }
                                        },
                                        [_vm._v("Add")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    VCardText,
                    [
                      _c(
                        VList,
                        { attrs: { "max-width": "600" } },
                        [
                          _vm._l(_vm.currentRole.users, function(user, index) {
                            return [
                              index > 0
                                ? _c(VDivider, {
                                    key: index,
                                    attrs: { inset: "" }
                                  })
                                : _vm._e(),
                              _c(
                                VListItem,
                                { key: user.name },
                                [
                                  _c(
                                    VListItemAvatar,
                                    [
                                      _c(
                                        VAvatar,
                                        {
                                          attrs: {
                                            color: _vm.getColor(user.name)
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.nameInitials(user.name)
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    VListItemContent,
                                    [
                                      _c(VListItemTitle, [
                                        _vm._v(" " + _vm._s(user.name) + " ")
                                      ]),
                                      _c(VListItemSubtitle, [
                                        _vm._v(" " + _vm._s(user.email) + " ")
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    VListItemAction,
                                    [
                                      _c(
                                        VBtn,
                                        {
                                          attrs: { color: "error", icon: "" },
                                          on: {
                                            click: function($event) {
                                              return _vm.removeFromRole(user)
                                            }
                                          }
                                        },
                                        [
                                          _c(VIcon, [
                                            _vm._v("mdi-delete-outline")
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          })
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }