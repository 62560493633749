






























































import Vue from "vue";

export default Vue.extend({
  name: "App",
  data() {
    return {
      email: "",
      password: "",
    };
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    },

    loading() {
      return this.$store.getters.loading;
    },
  },
  watch: {
    currentUser(value) {
      if (value !== null && value !== undefined) {
        this.$router.push("/");
      }
    },
  },
  methods: {
    onSignin() {
      this.$store.dispatch("signIn", {
        email: this.email,
        password: this.password,
      });
    },
  },
});
