import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VContainer,
    { attrs: { "grid-list-md": "", "text-xs-center": "", "fill-height": "" } },
    [
      _c(
        VRow,
        { attrs: { align: "center", justify: "center" } },
        [
          _c(
            VForm,
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.onSignin($event)
                }
              }
            },
            [
              _c(
                VCard,
                {
                  staticClass: "elevation-12",
                  attrs: {
                    width: "400",
                    align: "center",
                    justify: "center",
                    outlined: ""
                  }
                },
                [
                  _c(VImg, {
                    staticClass: "mt-4",
                    attrs: {
                      src: require("@/assets/lime.svg"),
                      contain: "",
                      "max-width": "150"
                    }
                  }),
                  _c(VCardTitle, { staticClass: "justify-center" }, [
                    _vm._v(" Lime v1.1 ")
                  ]),
                  _c(
                    VCardText,
                    [
                      _c(VTextField, {
                        attrs: {
                          outlined: "",
                          id: "email",
                          label: "Email",
                          name: "email",
                          "prepend-icon": "mdi-email-outline",
                          type: "text",
                          required: ""
                        },
                        model: {
                          value: _vm.email,
                          callback: function($$v) {
                            _vm.email = $$v
                          },
                          expression: "email"
                        }
                      }),
                      _c(VTextField, {
                        attrs: {
                          outlined: "",
                          id: "password",
                          label: "Password",
                          name: "password",
                          "prepend-icon": "mdi-lock-outline",
                          type: "password",
                          required: ""
                        },
                        model: {
                          value: _vm.password,
                          callback: function($$v) {
                            _vm.password = $$v
                          },
                          expression: "password"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    VCardActions,
                    [
                      _c(VSpacer),
                      _c(
                        VBtn,
                        {
                          attrs: {
                            color: "primary",
                            type: "submit",
                            disabled: _vm.loading,
                            loading: _vm.loading,
                            outlined: ""
                          }
                        },
                        [_vm._v("Sign in")]
                      ),
                      _c(VSpacer)
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }