




























































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import LineChart from "@/components/LineChart.vue";
import AccessCard from "@/components/AccessCard.vue";
//import LicenceUser from "@/components/LicenceUser.vue";
import { ChartDataSets } from "chart.js";
import DailyStatistics from "@/types/DailyStatistics.ts";
import HourlyStatistics from "@/types/HourlyStatistics.ts";
import Licence from "@/types/Licence";
import User from "@/types/User";
import HashColor from "@/plugins/hashcolor";
import Access from "@/types/Access";
import firebase from "firebase";
import Seat from "@/types/Seat";

import axios from "axios";

export default Vue.extend({
  name: "App",
  components: {
    LineChart,
    AccessCard,
    //LicenceUser,
  },
  data: () => ({
    tab: null,
    fetchStatisticsLoader: false,
    expirationModal: null,
    expirationStepper: null,
    expirationDate: null,
    expirationTime: null,
    maintenanceModal: null,
    maintenanceStepper: null,
    maintenanceDate: null,
    maintenanceTime: null,
    refreshStatusLoader: false,
    refreshFinished: false,
    accesses: new Array<Access>(),
    seatLoading: false,
    addToSeatAccessId: null,
    newSeatName: "",
    updateSeatName: "",
  }),

  computed: {
    currentLicence(): Licence {
      return this.$store.getters.inspectLicence(this.$route.params.licenceId);
    },

    inspectUser(): User {
      return this.$store.getters.inspectUser;
    },

    inspectAccesses() {
      if (this.$store.getters.inspectAccesses)
        return this.$store.getters.inspectAccesses;
      else return new Array<Access>();
    },

    inspectNonEmptyAccesses() {
      if (this.$store.getters.inspectAccesses)
        return this.$store.getters.inspectAccesses.filter(
          (acc: Access) => acc?.userId
        );
      else return new Array<Access>();
    },

    inspectSeats() {
      return this.$store.getters.inspectSeats;
    },

    attachmentData() {
      return this.$store.getters.attachmentData;
    },

    licenceSessions() {
      return this.$store.getters.licenceSessions.filter(
        (e: any) => e.licenceId === this.currentLicence?.id
      );
    },

    allowedUsers() {
      return null;
      //return this.$store.getters.users.filter((e: User) => this.currentLicence.allowedUsers.includes( e?.id as string ));
    },

    licenceSessionSummary() {
      let totalTime = 0;
      let lastUsed = 0;

      const chartByDays: DailyStatistics[] = [];
      const chartByHours: HourlyStatistics[] = [];

      for (let i = 0; i < 24; i++) {
        chartByHours.push({
          hour: i,
          opening: 0,
        });
      }

      //! Main loop for licenceSessions
      this.licenceSessions.forEach((element: any) => {
        // Total usage time
        totalTime += (element.end - element.start) / 60000;
        // Last usae time
        lastUsed = element.start > lastUsed ? element.start : lastUsed;
        // Start time as Date object
        const start = new Date(element.start);

        const day = start.toLocaleDateString();
        const stat = chartByDays.find((el) => el.label === day);
        if (stat) {
          stat.opening++;
          stat.timeUsed += (element.end - element.start) / 60000;
        } else {
          chartByDays.push({
            label: day,
            opening: 1,
            timeUsed: (element.end - element.start) / 60000,
          });
        }

        const hour = start.getHours();
        const hourStat = chartByHours.find((el) => el.hour === hour);
        if (hourStat) {
          hourStat.opening++;
        }
      });

      // Sort by Date (ascending)
      chartByDays.sort((a, b) => {
        return a.label > b.label ? 1 : -1;
      });

      // If not enough data, fill with blank
      if (chartByDays.length < 5) {
        const blank: DailyStatistics = {
          label: "",
          timeUsed: 0,
          opening: 0,
        };

        chartByDays.unshift(blank);
        chartByDays.push(blank);
      }

      // Sort by Hour (ascending)
      chartByHours.sort((a, b) => {
        return a.hour > b.hour ? 1 : -1;
      });

      // Chart data structure
      const lineData: number[] = chartByDays.map((e) => Math.round(e.timeUsed));
      const barData: number[] = chartByDays.map((e) => e.opening);
      const hourData: number[] = chartByHours.map((e) => e.opening);

      const lineDataSet: ChartDataSets = {
        label: "Használati idő",
        data: lineData,
      };

      const barDataSet: ChartDataSets = {
        label: "Megnyitások",
        data: barData,
        type: "bar",
        yAxisID: "right-axis",
        backgroundColor: "rgb(23, 164, 241, 0.4)",
        borderColor: "rgb(23, 164, 241, 0.7)",
        borderWidth: 1,
      };

      const hourDataSet: ChartDataSets = {
        label: "Megnyitások óránként (Jelen időzónában)",
        data: hourData,
      };

      // Chart data assignment
      const chartByDayData: Chart.ChartDataSets[] = [lineDataSet, barDataSet];
      const chartByDayLabels: string[] = chartByDays.map((e) => e.label);
      const chartByHourData: ChartDataSets[] = [hourDataSet];
      const chartByHoursLabels: string[] = chartByHours.map((e) =>
        e.hour.toString()
      );

      // Daily statistics chart scales
      const chartByDayScales = {
        xAxes: [{ display: true, stacked: true }],
        yAxes: [
          {
            type: "linear",
            id: "left-axis",
            display: true,
            position: "left",
            scaleLabel: { display: true, labelString: "perc" },
          },
          {
            type: "linear",
            id: "right-axis",
            display: true,
            position: "right",
            stacked: false,
            ticks: {
              beginAtZero: true,
              stepSize: 2,
            },
            gridLines: { drawOnChartArea: false },
          },
        ],
      };

      return {
        totalTime: Math.round(totalTime),
        lastUsed,
        chartByDayData,
        chartByDayLabels,
        chartByDayScales,
        chartByHoursLabels,
        chartByHourData,
      };
    },
  },
  watch: {
    inspectSeats: function (data) {
      this.newSeatName = `Seat ${data.length + 1}`;
    },
  },

  methods: {
    async removeUserFromSeat(access: Access, seat: Seat) {
      if (
        confirm(
          `Are you sure you want to remove ${access?.userName} from ${seat?.name}?`
        )
      ) {
        const idToken = await firebase.auth().currentUser?.getIdToken();

        if (idToken && access && this.currentLicence?.id && seat) {
          this.seatLoading = true;

          try {
            const response = await axios.delete(
              `${process.env.VUE_APP_API_BASEURL}licence/seat`,
              {
                headers: {
                  authorization: `Bearer ${idToken}`,
                },
                data: {
                  licenceId: this.currentLicence.id,
                  accessId: access.id,
                  seatId: seat.id,
                },
              }
            );

            if (response.status === 200) {
              this.seatLoading = false;
              this.$store.dispatch(
                "setSuccess",
                "User successfully removed from seat"
              );
            } else {
              this.seatLoading = false;
              this.$store.dispatch("setError", response.data.error.message);
            }
          } catch (error) {
            this.seatLoading = false;
            const errorMsg = error.response?.data?.error?.message;
            if (errorMsg) {
              this.$store.dispatch("setError", errorMsg);
            }
          }
        }
      }
    },

    async addUserToSeat(seat: Seat) {
      const idToken = await firebase.auth().currentUser?.getIdToken();

      if (
        idToken &&
        this.addToSeatAccessId &&
        this.currentLicence?.id &&
        seat
      ) {
        this.seatLoading = true;

        try {
          const response = await axios.put(
            `${process.env.VUE_APP_API_BASEURL}licence/seat`,
            {
              licenceId: this.currentLicence.id,
              accessId: this.addToSeatAccessId,
              seatId: seat.id,
            },
            {
              headers: {
                authorization: `Bearer ${idToken}`,
              },
            }
          );

          if (response.status === 200) {
            this.seatLoading = false;
            this.$store.dispatch(
              "setSuccess",
              "User successfully added to seat"
            );
          } else {
            this.seatLoading = false;
            this.$store.dispatch("setError", response.data.error.message);
          }
        } catch (error) {
          this.seatLoading = false;
          const errorMsg = error.response?.data?.error?.message;
          if (errorMsg) {
            this.$store.dispatch("setError", errorMsg);
          }
        }
      }
    },

    async addSeat() {
      const idToken = await firebase.auth().currentUser?.getIdToken();

      if (idToken && this.newSeatName && this.currentLicence) {
        this.seatLoading = true;

        try {
          const response = await axios.post(
            `${process.env.VUE_APP_API_BASEURL}seat`,
            {
              licenceId: this.currentLicence.id,
              name: this.newSeatName,
            },
            {
              headers: {
                authorization: `Bearer ${idToken}`,
              },
            }
          );

          if (response.status === 200) {
            this.seatLoading = false;
            this.$store.dispatch(
              "setSuccess",
              "Seat has been successfully added to licence"
            );
          } else {
            this.seatLoading = false;
            this.$store.dispatch("setError", response.data.error.message);
          }
        } catch (error) {
          this.seatLoading = false;
          const errorMsg = error.response?.data?.error?.message;
          if (errorMsg) {
            this.$store.dispatch("setError", errorMsg);
          }
        }
      }
    },

    async seatNameUpdate(seat: Seat) {
      if (this.inspectUser && this.currentLicence && seat)
        try {
          this.seatLoading = true;
          await firebase
            .firestore()
            .collection("users")
            .doc(this.inspectUser.id)
            .collection("licences")
            .doc(this.currentLicence.id)
            .collection("seats")
            .doc(seat.id)
            .update({ name: this.updateSeatName });
          this.$store.dispatch(
            "setSuccess",
            "Seat name has been successfully modified"
          );
          this.seatLoading = false;
        } catch (error) {
          this.$store.dispatch("setError", error);
          this.seatLoading = false;
        }
    },

    async deleteSeat(seat: Seat) {
      if (confirm(`Are you sure you want to delete ${seat?.name}?`)) {
        if (this.inspectUser && this.currentLicence && seat)
          try {
            this.seatLoading = true;
            await firebase
              .firestore()
              .collection("users")
              .doc(this.inspectUser.id)
              .collection("licences")
              .doc(this.currentLicence.id)
              .collection("seats")
              .doc(seat.id)
              .delete();
            this.$store.dispatch(
              "setSuccess",
              "Seat has been successfully deleted"
            );
            this.seatLoading = false;
          } catch (error) {
            this.$store.dispatch("setError", error);
            this.seatLoading = false;
          }
      }
    },

    nameInitials(name: string) {
      if (!name) return "?";
      const array = name.split(" ");
      let initials = "";

      array.forEach((element) => (initials += element.charAt(0)));

      if (initials.length === 0) {
        return "?";
      } else {
        return initials;
      }
    },

    getUserName(accessId: string) {
      const access = this.$store.getters.inspectAccesses.find(
        (acc: Access) => acc?.id === accessId
      );
      return access?.userName;
    },

    getUserEmail(accessId: string) {
      const access = this.$store.getters.inspectAccesses.find(
        (acc: Access) => acc?.id === accessId
      );
      return access?.email;
    },

    getColor(what: string) {
      return HashColor.get(what ? what : "?");
    },

    capitalizeStr(str: string) {
      return str.charAt(0).toLocaleUpperCase() + str.slice(1);
    },

    modifyState(state: string): void {
      const data = {
        id: this.currentLicence?.id,
        licenceStatus: state,
      };

      this.$store.dispatch("modifyLicence", data);
    },

    modifyLicence(data: object): void {
      const id = { id: this.currentLicence?.id };
      this.$store.dispatch("modifyLicence", { ...id, ...data });
    },

    deleteLicence(): void {
      if (confirm("Are you sure you want to delete this licence?")) {
        this.$store.dispatch("deleteUserLicence", this.currentLicence?.id);
        this.$router.push(`/user/${this.$route.params.userId}`);
      }
    },

    goToUser(): void {
      this.$router.push(`/user/${this.$route.params.userId}`);
    },

    userEmail(userId: string): string {
      return this.$store.getters.userEmail(userId);
    },

    getFileType(fileName: string): string {
      const extension = fileName.substr(fileName.lastIndexOf(".") + 1);
      let type: string;

      switch (extension) {
        case "jpeg":
        case "jpg":
        case "png":
        case "bmp":
          type = "image";
          break;
        case "pdf":
          type = "pdf";
          break;
        default:
          type = "undefined";
      }

      return type;
    },

    async fetchLicenceSessions() {
      this.fetchStatisticsLoader = true;
      const data = {
        id: this.currentLicence?.id,
      };

      await this.$store.dispatch("fetchLicenceSessions", data);

      this.fetchStatisticsLoader = false;
    },

    saveExpiration() {
      const newExpirationDate = new Date(
        this.expirationDate + "T" + this.expirationTime
      );
      this.modifyLicence({ validUntil: newExpirationDate.getTime() });
    },

    saveMaintenance() {
      const newMaintenanceDate = new Date(
        this.maintenanceDate + "T" + this.maintenanceTime
      );
      this.modifyLicence({ muValidUntil: newMaintenanceDate.getTime() });
    },

    async refreshOnlineUsers() {
      this.refreshStatusLoader = true;
      await this.$store.dispatch("fetchOnlineUsers");
      this.refreshFinished = true;
    },

    refreshOnlineUsersFinish() {
      this.refreshStatusLoader = false;
      this.refreshFinished = false;
    },
  },

  /*   watch:
  {
    async inspectAccesses (newData: Access[])
    {
      await Promise.all(newData.map(async acc=>{
        let user=undefined;
        if(acc?.userId)
          user=await firebase.firestore().collection('users').doc(acc?.userId).get();
        const membership=await firebase.firestore().collection('memberships').doc(acc?.membershipId).get();

        if(acc)
        {
          acc.userName=user?.data()?.name;
          acc.email=user?.data()?.email;
          acc.membershipType=membership.data()?.type;
          acc.membershipExpirationDate=membership.data()?.expirationDate;
        }

        this.accesses.push(acc);
      }));
    } 
  },*/

  created() {
    this.$store.dispatch("fetchInspectUser", this.$route.params.userId);
    this.$store.dispatch("fetchUserLicences", this.$route.params.userId);
    this.$store.dispatch("fetchInspectAccesses", {
      userId: this.$route.params.userId,
      licenceId: this.$route.params.licenceId,
    });
    this.$store.dispatch("fetchInspectSeats", {
      userId: this.$route.params.userId,
      licenceId: this.$route.params.licenceId,
    });

    this.$store.dispatch("fetchAttachmentData", this.$route.params);
  },
});
