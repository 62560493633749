import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VCard,
    {
      staticClass: "ma-2 flex d-flex flex-column",
      attrs: { outlined: "", loading: _vm.loading, disabled: _vm.loading }
    },
    [
      _c(
        VCardTitle,
        [
          _vm._v(" " + _vm._s(_vm.capitalizeStr(_vm.type)) + " "),
          _c(VSpacer),
          _vm._v(
            _vm._s(_vm.capacity) + "/" + _vm._s(_vm.accessesByType.length) + " "
          ),
          _c(
            VMenu,
            {
              attrs: {
                transition: "slide-y-transition",
                "close-on-content-click": false,
                bottom: ""
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var attrs = ref.attrs
                    var on = ref.on
                    return [
                      _c(
                        VBtn,
                        _vm._g(
                          _vm._b(
                            {
                              staticClass: "ml-3",
                              attrs: {
                                "x-small": "",
                                outlined: "",
                                fab: "",
                                color: "info"
                              }
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [_c(VIcon, [_vm._v("mdi-plus")])],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [
              _c(
                VCard,
                [
                  _c(
                    VCardText,
                    [
                      _c(
                        VMenu,
                        {
                          attrs: {
                            "close-on-content-click": false,
                            "nudge-right": 40,
                            transition: "scale-transition",
                            "offset-y": "",
                            "min-width": "290px"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    VTextField,
                                    _vm._g(
                                      {
                                        attrs: {
                                          outlined: "",
                                          dense: "",
                                          label: "Expiration",
                                          "prepend-icon": "mdi-calendar-clock",
                                          readonly: "",
                                          required: ""
                                        },
                                        model: {
                                          value: _vm.membershipExpirationDate,
                                          callback: function($$v) {
                                            _vm.membershipExpirationDate = $$v
                                          },
                                          expression: "membershipExpirationDate"
                                        }
                                      },
                                      on
                                    )
                                  )
                                ]
                              }
                            }
                          ]),
                          model: {
                            value: _vm.dateMenu,
                            callback: function($$v) {
                              _vm.dateMenu = $$v
                            },
                            expression: "dateMenu"
                          }
                        },
                        [
                          _c(VDatePicker, {
                            attrs: { small: "" },
                            on: {
                              input: function($event) {
                                _vm.dateMenu = false
                              }
                            },
                            model: {
                              value: _vm.membershipExpirationDate,
                              callback: function($$v) {
                                _vm.membershipExpirationDate = $$v
                              },
                              expression: "membershipExpirationDate"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    VCardActions,
                    [
                      _c(VSpacer),
                      _c(
                        VBtn,
                        {
                          attrs: { outlined: "", color: "info" },
                          on: {
                            click: function($event) {
                              return _vm.addAccess()
                            }
                          }
                        },
                        [_vm._v("Add")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        VCardText,
        { staticClass: "flex" },
        [
          _c(
            VList,
            { attrs: { "two-line": "", align: "left", nav: "" } },
            [
              _vm._l(_vm.accessesByType, function(access, index) {
                return [
                  index > 0
                    ? _c(VDivider, { key: index, attrs: { inset: "" } })
                    : _vm._e(),
                  _c(
                    VListItem,
                    { key: access.id },
                    [
                      _c(
                        VListItemAvatar,
                        [
                          access.email
                            ? _c(
                                VAvatar,
                                {
                                  attrs: { color: _vm.getColor(access.email) }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.nameInitials(access.userName)
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            : _c(
                                VMenu,
                                {
                                  attrs: {
                                    transition: "slide-y-transition",
                                    "close-on-content-click": false,
                                    bottom: ""
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var attrs = ref.attrs
                                          var on = ref.on
                                          return [
                                            _c(
                                              VBtn,
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      fab: "",
                                                      color: "info"
                                                    }
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c(VIcon, [
                                                  _vm._v(
                                                    "mdi-account-plus-outline"
                                                  )
                                                ])
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [
                                  _c(
                                    VCard,
                                    [
                                      _c(VCardTitle, [
                                        _vm._v(" Add user to access ")
                                      ]),
                                      _c(
                                        VCardText,
                                        [
                                          _c(VTextField, {
                                            attrs: {
                                              "prepend-icon":
                                                "mdi-email-outline",
                                              label: "Email",
                                              outlined: "",
                                              dense: ""
                                            },
                                            model: {
                                              value: _vm.addUserEmail,
                                              callback: function($$v) {
                                                _vm.addUserEmail = $$v
                                              },
                                              expression: "addUserEmail"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        VCardActions,
                                        [
                                          _c(VSpacer),
                                          _c(
                                            VBtn,
                                            {
                                              attrs: {
                                                outlined: "",
                                                color: "info",
                                                disabled: !_vm.addUserEmail
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.addUserToAccess(
                                                    access.membershipId
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("Add")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                        ],
                        1
                      ),
                      access.email
                        ? _c(
                            VListItemContent,
                            [
                              _c(VListItemTitle, [
                                _vm._v(" " + _vm._s(access.userName) + " ")
                              ]),
                              _c(VListItemSubtitle, [
                                _vm._v(" " + _vm._s(access.email) + " ")
                              ])
                            ],
                            1
                          )
                        : _c(
                            VListItemContent,
                            [
                              _c(VListItemTitle, [
                                _vm._v(" Empty access ")
                              ]),
                              _c(VListItemSubtitle, [
                                _vm._v(" Add user to the access ")
                              ])
                            ],
                            1
                          ),
                      _c(
                        VListItemAction,
                        [
                          _c(VHover, {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var hover = ref.hover
                                    return [
                                      _vm.isExpired(
                                        access.membershipExpirationDate
                                      )
                                        ? _c(
                                            VChip,
                                            {
                                              attrs: {
                                                outlined: "",
                                                dense: "",
                                                small: "",
                                                color: "grey"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  hover
                                                    ? new Date(
                                                        access.membershipExpirationDate
                                                      ).toLocaleDateString()
                                                    : "Expired"
                                                )
                                              )
                                            ]
                                          )
                                        : _vm.isExpireSoon(
                                            access.membershipExpirationDate
                                          )
                                        ? _c(
                                            VChip,
                                            {
                                              attrs: {
                                                outlined: "",
                                                dense: "",
                                                small: "",
                                                color: "warning"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  hover
                                                    ? new Date(
                                                        access.membershipExpirationDate
                                                      ).toLocaleDateString()
                                                    : "Expire soon"
                                                )
                                              )
                                            ]
                                          )
                                        : _c(
                                            VChip,
                                            {
                                              attrs: {
                                                outlined: "",
                                                dense: "",
                                                small: "",
                                                color: "success"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  hover
                                                    ? new Date(
                                                        access.membershipExpirationDate
                                                      ).toLocaleDateString()
                                                    : "Active"
                                                )
                                              )
                                            ]
                                          )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        ],
                        1
                      ),
                      _c(
                        VListItemAction,
                        [
                          _c(
                            VMenu,
                            {
                              attrs: {
                                transition: "slide-y-transition",
                                bottom: ""
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var attrs = ref.attrs
                                      var on = ref.on
                                      return [
                                        _c(
                                          VBtn,
                                          _vm._g(
                                            _vm._b(
                                              { attrs: { icon: "" } },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c(VIcon, [
                                              _vm._v("mdi-dots-vertical")
                                            ])
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            },
                            [
                              _c(
                                VList,
                                { attrs: { nav: "", dense: "" } },
                                [
                                  access.membershipId
                                    ? _c(
                                        VListItem,
                                        {
                                          attrs: {
                                            to:
                                              "/membership/" +
                                              access.membershipId
                                          }
                                        },
                                        [
                                          _c(
                                            VListItemIcon,
                                            [
                                              _c(VIcon, [
                                                _vm._v("mdi-chevron-right")
                                              ])
                                            ],
                                            1
                                          ),
                                          _c(VListItemTitle, [
                                            _vm._v("Go to membership")
                                          ])
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  access.userId
                                    ? _c(
                                        VListItem,
                                        {
                                          attrs: {
                                            to: "/user/" + access.userId
                                          }
                                        },
                                        [
                                          _c(
                                            VListItemIcon,
                                            [
                                              _c(VIcon, [
                                                _vm._v("mdi-chevron-right")
                                              ])
                                            ],
                                            1
                                          ),
                                          _c(VListItemTitle, [
                                            _vm._v("Go to user")
                                          ])
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  access.userId
                                    ? _c(VDivider, {
                                        staticClass: "mr-5 ml-5"
                                      })
                                    : _vm._e(),
                                  access.userId
                                    ? _c(
                                        VListItem,
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.removeUserFromAccess(
                                                access
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            VListItemIcon,
                                            [
                                              _c(
                                                VIcon,
                                                { attrs: { color: "error" } },
                                                [_vm._v(" mdi-delete-outline ")]
                                              )
                                            ],
                                            1
                                          ),
                                          _c(VListItemTitle, [
                                            _vm._v("Remove user")
                                          ])
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    VListItem,
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.deleteAccess(access)
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        VListItemIcon,
                                        [
                                          _c(
                                            VIcon,
                                            { attrs: { color: "error" } },
                                            [_vm._v(" mdi-delete-outline ")]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(VListItemTitle, [
                                        _vm._v("Delete access")
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              })
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }