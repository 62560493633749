



















































































































import Vue from "vue";
import * as XLSX from "xlsx";

export default Vue.extend({
  data: () => ({
    dialog: false,
    startMenu: false,
    endMenu: false,
    sortBy: "buildDate",
    desc: true,
    search: "",
    localType: "",
    localEndDate: new Date().toISOString().substr(0, 10),
    localStartDate: new Date().toISOString().substr(0, 10),
    loadingBtn: false,
    headers: [
      {
        text: "Name",
        value: "name",
      },
      {
        text: "Email",
        value: "email",
      },
      {
        text: "Licence Type",
        value: "licenceType",
      },
      {
        text: "Licence Period",
        value: "period",
      },
      {
        text: "Times opened",
        value: "timesOpened",
      },
      {
        text: "Overall usege (Minutes)",
        value: "overallUse",
        align: "center",
      },
    ],
  }),

  computed: {
    startDate: {
      get() {
        return this.$store.getters.startDate;
      },
      set(date: string) {
        this.$store.commit("setStartDate", date);
      },
    },

    endDate: {
      get() {
        return this.$store.getters.endDate;
      },
      set(date: string) {
        this.$store.commit("setEndDate", date);
      },
    },

    licenceType: {
      get() {
        return this.$store.getters.licenceType;
      },
      set(type: string) {
        this.$store.commit("setLicenceType", type);
      },
    },

    createdStatistics: {
      get() {
        return this.$store.getters.createdStatistics;
      },
      set(data: boolean) {
        this.$store.commit("setCreatedStatistics", data);
      },
    },

    statistics: function() {
      return this.$store.getters.reportData.map((item: any) => {
        return {
          userId: item.user.id,
          name: item.user.name,
          email: item.user.email,
          licenceType: item.licenceType,
          period: item.licencePeriod,
          timesOpened: item.statistics.count,
          overallUse: Math.ceil(item.statistics.time / 60 / 1000),
        };
      });
    },
  },

  methods: {
    async onSubmit() {
      this.loadingBtn = true;
      this.createdStatistics = false;

      if (
        this.$store.getters.reportData.length !== 0 &&
        this.localEndDate === this.endDate &&
        this.localStartDate === this.startDate &&
        this.localType === this.licenceType
      ) {
        this.$store.dispatch("setError", "No changes!");
        this.createdStatistics = true;
        this.loadingBtn = false;
        return;
      }

      if (!this.localType) {
        this.$store.dispatch("setError", "Choose licence type!");
        this.loadingBtn = false;
        return;
      }

      if (this.localStartDate > this.localEndDate) {
        this.$store.dispatch("setError", "Invalid interval!");
        this.loadingBtn = false;
        return;
      }

      const inputs = {
        startInterval: new Date(this.localStartDate).getTime(),
        endInterval: new Date(this.localEndDate).getTime(),
        licenceType: this.localType,
      };

      await this.$store.dispatch("fetchReportData", inputs);

      if (this.$store.getters.reportData.length !== 0)
        this.createdStatistics = true;

      this.endDate = this.localEndDate;
      this.startDate = this.localStartDate;
      (this.licenceType = this.localType), (this.loadingBtn = false);
    },

    exportStatistics() {
      if (this.$store.getters.reportData.length === 0) return;

      this.loadingBtn = true;
      this.createdStatistics = false;

      const flattendStatistics = this.$store.getters.reportData.map(
        (item: any) => {
          return [
            item.user.id,
            item.user.name,
            item.user.email,
            item.user.location.zipcode,
            item.user.location.country,
            item.user.location.town,
            item.user.location.street,
            item.user.location.streetNumber,
            item.user.contact.phoneNumber,
            item.user.company.sector,
            item.user.company.name,
            // Ha talalunk hibas url-t akkor nem kerjuk mert megall az excel generalas
            item.user.company.url.length > 200 ? "" : item.user.company.url,
            item.user.company.size,
            item.user.company.position,
            item.user.university.name,
            item.user.interest,
            item.licenceType,
            item.licencePeriod,
            new Date(item.licenceCreationDate),
            new Date(item.licenceExpirationDate),
            item.statistics.count,
            Math.ceil(item.statistics.time / 60 / 1000),
          ];
        }
      );

      const workSheetName = `Statistics_${new Date()
        .toISOString()
        .substr(0, 10)}.xlsx`;
      const workSheetColumnName = [
        "User ID",
        "Name",
        "Email",
        "Location Zipcode",
        "Location Country",
        "Location Town",
        "Location Street",
        "Location Street Number",
        "Contact Phone Number",
        "Company Sector",
        "Company Name",
        "Company Url",
        "Company Size",
        "Company Position",
        "University Name",
        "Interest",
        "Licence Type",
        "Licence Period",
        "Licence Creation Date",
        "Licence Expiration Date",
        "Times Opened",
        "Overall Use (Minutes)",
      ];

      const workBook = XLSX.utils.book_new();
      const workSheetData = [workSheetColumnName, ...flattendStatistics];
      const workSheet = XLSX.utils.aoa_to_sheet(workSheetData, {
        cellDates: true,
      });
      XLSX.utils.book_append_sheet(workBook, workSheet, workSheetName);

      try {
        XLSX.writeFile(workBook, workSheetName);
      } catch (err) {
        this.$store.dispatch("setError", "Error while exporting data!");
      }

      this.loadingBtn = false;
      this.createdStatistics = true;
    },

    onRowClick(user: any, row: any) {
      this.$router.push(`/user/${user.userId}`);
    },

    allowedEndDates(val: string) {
      return new Date(val).getTime() >= new Date(this.localStartDate).getTime();
    },

    allowedStartDates(val: string) {
      return new Date(val).getTime() <= new Date(this.localEndDate).getTime();
    },
  },

  mounted() {
    if (this.startDate != this.localStartDate)
      this.localStartDate = this.startDate;
    if (this.endDate != this.localEndDate) this.localEndDate = this.endDate;
    if (this.licenceType != this.localType) this.localType = this.licenceType;
  },
});
