












import Vue from "vue";
import Chart, { ChartScales } from "chart.js";

export default Vue.component("line-chart", {
  name: "LineChart",
  props: {
    chartData: {
      type: Array,
      required: true,
    },
    labels: {
      type: Array,
      required: true,
    },
    height: {
      default: "auto",
      required: false,
      type: String,
    },
    beginAtZero: {
      default: true,
      required: false,
      type: Boolean,
    },
    scales: {
      required: false,
      default: function (): ChartScales {
        return {
          yAxes: [
            {
              type: "linear",
              display: true,
              position: "left",
              ticks: {
                beginAtZero: this.beginAtZero,
                stepSize: 1,
              },
            },
          ],
        };
      },
    },
    legendDisplay: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data: () => ({}),
  methods: {
    createChart() {
      const ctx: any = this.$refs.chart;
      const myChart = new Chart(ctx, {
        type: "line",
        data: {
          labels: this.labels as string[],
          datasets: this.chartData as Chart.ChartDataSets[],
        },
        options: {
          legend: {
            display: this.legendDisplay,
          },
          maintainAspectRatio: false,
          responsive: true,
          scales: this.scales,
        },
      });

      if (myChart.canvas?.parentNode)
        (myChart.canvas.parentNode as HTMLElement).style.height = this.height;
    },
  },

  watch: {
    chartData(newVal) {
      this.createChart();
    },

    labels() {
      this.createChart();
    },
  },

  mounted() {
    this.createChart();
  },
});
