import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VRow,
    { attrs: { justify: "center" } },
    [
      _c(
        VDialog,
        {
          attrs: { "max-width": "600px" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    VBtn,
                    _vm._g(
                      {
                        staticClass: "ma-5",
                        attrs: { outlined: "", color: "info" }
                      },
                      on
                    ),
                    [
                      _c(VIcon, [_vm._v("mdi-plus")]),
                      _vm._v(" Add new membership ")
                    ],
                    1
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            VCard,
            [
              _c(
                VContainer,
                [
                  _c(
                    VForm,
                    {
                      nativeOn: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.onSubmit($event)
                        }
                      }
                    },
                    [
                      _c(
                        VRow,
                        { staticClass: "mt-2" },
                        [
                          _c(
                            VCol,
                            { attrs: { cols: "6" } },
                            [
                              _c(VAutocomplete, {
                                attrs: {
                                  outlined: "",
                                  dense: "",
                                  items: ["basic", "pro", "premium"],
                                  label: "Memberhsip type",
                                  id: "accessMembershipType",
                                  name: "accessMembershipType",
                                  "prepend-icon": "mdi-wallet-membership",
                                  required: ""
                                },
                                model: {
                                  value: _vm.accessMembershipType,
                                  callback: function($$v) {
                                    _vm.accessMembershipType = $$v
                                  },
                                  expression: "accessMembershipType"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            VCol,
                            { attrs: { cols: "4" } },
                            [
                              _c(
                                VMenu,
                                {
                                  attrs: {
                                    "close-on-content-click": false,
                                    "nudge-right": 40,
                                    transition: "scale-transition",
                                    "offset-y": "",
                                    "min-width": "290px"
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            VTextField,
                                            _vm._g(
                                              {
                                                attrs: {
                                                  outlined: "",
                                                  dense: "",
                                                  label: "Expiration",
                                                  "prepend-icon":
                                                    "mdi-calendar-clock",
                                                  readonly: "",
                                                  required: ""
                                                },
                                                model: {
                                                  value: _vm.date,
                                                  callback: function($$v) {
                                                    _vm.date = $$v
                                                  },
                                                  expression: "date"
                                                }
                                              },
                                              on
                                            )
                                          )
                                        ]
                                      }
                                    }
                                  ]),
                                  model: {
                                    value: _vm.menu,
                                    callback: function($$v) {
                                      _vm.menu = $$v
                                    },
                                    expression: "menu"
                                  }
                                },
                                [
                                  _c(VDatePicker, {
                                    attrs: { small: "" },
                                    on: {
                                      input: function($event) {
                                        _vm.menu = false
                                      }
                                    },
                                    model: {
                                      value: _vm.date,
                                      callback: function($$v) {
                                        _vm.date = $$v
                                      },
                                      expression: "date"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            VCol,
                            { attrs: { cols: "1" } },
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    outlined: "",
                                    fab: "",
                                    small: "",
                                    color: "info",
                                    disabled:
                                      _vm.accessMembershipType.length === 0
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.onAddAccess()
                                    }
                                  }
                                },
                                [_c(VIcon, [_vm._v("mdi-plus")])],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.accesses.length
                        ? _c(
                            VList,
                            {
                              staticClass: "mb-5",
                              attrs: { dense: "", rounded: "" }
                            },
                            _vm._l(_vm.accesses, function(acc) {
                              return _c(
                                VListItem,
                                { key: acc.id, attrs: { "two-line": "" } },
                                [
                                  acc.membershipType === "basic"
                                    ? _c(
                                        VListItemAvatar,
                                        [
                                          _c(VIcon, [
                                            _vm._v("mdi-chevron-up")
                                          ])
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  acc.membershipType === "pro"
                                    ? _c(
                                        VListItemAvatar,
                                        [
                                          _c(VIcon, [
                                            _vm._v("mdi-chevron-double-up")
                                          ])
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  acc.membershipType === "premium"
                                    ? _c(
                                        VListItemAvatar,
                                        [
                                          _c(VIcon, [
                                            _vm._v("mdi-chevron-triple-up")
                                          ])
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    VListItemContent,
                                    [
                                      _c(VListItemTitle, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.capitalizeStr(
                                                acc.membershipType
                                              )
                                            ) +
                                            " "
                                        )
                                      ]),
                                      _c(VListItemSubtitle, [
                                        _vm._v(
                                          " " + _vm._s(acc.expiration) + " "
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    VListItemAction,
                                    [
                                      _c(
                                        VBtn,
                                        {
                                          attrs: {
                                            outlined: "",
                                            fab: "",
                                            "x-small": "",
                                            color: "error"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.onDeleteAccess(acc.id)
                                            }
                                          }
                                        },
                                        [
                                          _c(VIcon, [
                                            _vm._v("mdi-trash-can-outline")
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        : _vm._e(),
                      _c(
                        VBtn,
                        {
                          attrs: {
                            outlined: "",
                            color: "primary",
                            disabled: _vm.btnDisable
                          },
                          on: {
                            click: function($event) {
                              return _vm.onSubmit()
                            }
                          }
                        },
                        [_vm._v(" Submit ")]
                      ),
                      _c(
                        VBtn,
                        {
                          attrs: { text: "", color: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.onCancel()
                            }
                          }
                        },
                        [_vm._v(" Cancel ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }