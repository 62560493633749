




























































































































import Vue from "vue";
import AddAccessData from "../types/AddAccessData";

export default Vue.extend({
  name: "App",

  data: () => ({
    date: new Date().toISOString().substr(0, 10),
    type: "",
    dialog: false,
    menu: false,
    step: 1,
    productName: "",
    accessMembershipType: "",
    accesses: new Array<AddAccessData>(),
    numberOfSeats: 1,
  }),

  computed: {
    loading() {
      return this.$store.getters.loading;
    },

    users() {
      return this.$store.getters.users;
    },

    btnDisable: function () {
      return this.accesses.length === 0;
    },
  },

  methods: {
    onCancel() {
      this.dialog = false;
      this.accesses = new Array<AddAccessData>()
    },

    onSubmit() {
      const finalAccesses = this.accesses.map((acc) => {
        return {
          membershipType: acc.membershipType,
          expirationDate: new Date(acc.expiration).getTime(),
        }
      })

      const membership = {
        creationDate: new Date().getTime(),
        userId: this.$store.getters.inspectUser.id,
        accesses: finalAccesses,
      };

      this.$store.dispatch("addUserMembership", membership);

      this.dialog = false;
      this.accesses = new Array<AddAccessData>()
    },

    onAddAccess() {
      const accessData: AddAccessData = {
        id: this.accesses.length + 1,
        membershipType: this.accessMembershipType,
        expiration: this.date,
      };

      this.accesses.push(accessData);
    },

    onDeleteAccess(id: number) {
      this.accesses.splice(
        this.accesses.findIndex((access) => access.id === id),
        1
      );
    },

    capitalizeStr(str: string) {
      return str.charAt(0).toLocaleUpperCase() + str.slice(1);
    },
  },
});
