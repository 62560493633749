





























































































































































































































import HashColor from "@/plugins/hashcolor";
import Access from "@/types/Access";
import firebase from "firebase";
import axios from "axios";
import Vue from "vue";

export default Vue.component("access-card", {
  name: "AccessCard",
  data: () => ({
    tab: null,
    dateMenu: false,
    loading: false,
    addUserEmail: null,
    membershipExpirationDate: new Date().toISOString().substr(0, 10),
  }),
  props: {
    accesses: {
      type: Array as () => Array<Access>,
      required: true,
    },

    type: {
      type: String,
      required: true,
    },

    licenceId: {
      type: String,
      required: true,
    },
  },
  computed: {
    accessesByType() {
      const accesses = this.$store.getters.inspectAccesses.filter(
        (access: Access) => access?.membershipType === this.type
      );
      return accesses;
    },
    capacity() {
      const capacity = this.$store.getters.inspectAccesses
        .filter((access: Access) => access?.membershipType === this.type)
        .filter((access: Access) => access?.email).length;
      return capacity;
    },

    inspectUser() {
      return this.$store.getters.inspectUser;
    },
  },

  methods: {
    async removeUserFromAccess(access: Access) {
      if (
        confirm(
          `Are you sure you want to remove ${access?.userName} from their ${access?.membershipType} membership? This will remove their accesses to seats as well.`
        )
      ) {
        const idToken = await firebase.auth().currentUser?.getIdToken();

        if (idToken && access?.email) {
          this.loading = true;

          try {
            const response = await axios.delete(
              `${process.env.VUE_APP_API_BASEURL}licence/membership`,
              {
                headers: {
                  authorization: `Bearer ${idToken}`,
                },
                data: {
                  licenceId: this.licenceId,
                  userEmail: access.email,
                  membershipId: access.membershipId,
                },
              }
            );

            if (response.status === 200) {
              this.loading = false;
              this.$store.dispatch(
                "setSuccess",
                "User successfully removed from access"
              );
            } else {
              this.loading = false;
              this.$store.dispatch("setError", response.data.error.message);
            }
          } catch (error) {
            this.loading = false;
            const errorMsg = error.response?.data?.error?.message;
            if (errorMsg) {
              this.$store.dispatch("setError", errorMsg);
            }
          }
        }
      }
    },

    async addUserToAccess(membershipId: string) {
      const idToken = await firebase.auth().currentUser?.getIdToken();

      if (this.addUserEmail && idToken && this.licenceId) {
        this.loading = true; // eslint-disable-line

        try {
          const response = await axios.put(
            `${process.env.VUE_APP_API_BASEURL}licence/membership`,
            {
              licenceId: this.licenceId,
              userEmail: this.addUserEmail,
              membershipId: membershipId,
            },
            {
              headers: {
                authorization: `Bearer ${idToken}`,
              },
            }
          );

          if (response.status === 200) {
            this.loading = false;
            this.$store.dispatch(
              "setSuccess",
              "User successfully added to access"
            );
          } else {
            this.loading = false;
            this.$store.dispatch("setError", response.data.error.message);
          }
        } catch (error) {
          this.loading = false;
          const errorMsg = error.response?.data?.error?.message;
          if (errorMsg) {
            this.$store.dispatch("setError", errorMsg);
          }
        }
      }
    },
    async addAccess() {
      const idToken = await firebase.auth().currentUser?.getIdToken();

      if (idToken && this.membershipExpirationDate) {
        this.loading = true;

        try {
          const response = await axios.post(
            `${process.env.VUE_APP_API_BASEURL}access`,
            {
              licenceId: this.licenceId,
              expirationDate: new Date(this.membershipExpirationDate).getTime(),
              membershipType: this.type,
            },
            {
              headers: {
                authorization: `Bearer ${idToken}`,
              },
            }
          );

          if (response.status === 200) {
            this.loading = false;
            this.$store.dispatch(
              "setSuccess",
              "Access has been successfully added to licence"
            );
          } else {
            this.loading = false;
            this.$store.dispatch("setError", response.data.error.message);
          }
        } catch (error) {
          this.loading = false;
          const errorMsg = error.response?.data?.error?.message;
          if (errorMsg) {
            this.$store.dispatch("setError", errorMsg);
          }
        }
      }
    },

    async deleteAccess(access: Access) {
      if (
        confirm(
          `Are you sure you want to delete this ${access?.membershipType} access?`
        )
      ) {
        try {
          this.loading = true;
          if (access) {
            await firebase
              .firestore()
              .collection("users")
              .doc(this.inspectUser.id)
              .collection("licences")
              .doc(this.licenceId)
              .collection("accesses")
              .doc(access.id)
              .delete();
            this.$store.dispatch(
              "setSuccess",
              "Access has been successfully deleted"
            );
            this.loading = false;
          }
        } catch (error) {
          this.$store.dispatch("setError", error);
          this.loading = false;
        }
      }
    },

    capitalizeStr(str: string) {
      return str.charAt(0).toLocaleUpperCase() + str.slice(1);
    },

    nameInitials(name: string) {
      if (!name) return "?";
      const array = name.split(" ");
      let initials = "";

      array.forEach((element) => (initials += element.charAt(0)));

      if (initials.length === 0) {
        return "?";
      } else {
        return initials;
      }
    },

    isExpired(date: number) {
      return date < Date.now();
    },

    isExpireSoon(date: number) {
      const diff = date - Date.now();

      return diff > 0 && diff < 7 * 24 * 60 * 60 * 1000;
    },

    getColor(what: string) {
      return HashColor.get(what ? what : "?");
    },
  },
});
