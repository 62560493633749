import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VCard,
    { staticClass: "ma-3" },
    [
      _c(
        VCardTitle,
        [
          _vm._v(" Pending licences "),
          _c(VSpacer),
          _c(VTextField, {
            attrs: {
              outlined: "",
              dense: "",
              "prepend-icon": "mdi-magnify",
              label: "Search",
              "single-line": "",
              "hide-details": ""
            },
            model: {
              value: _vm.search,
              callback: function($$v) {
                _vm.search = $$v
              },
              expression: "search"
            }
          })
        ],
        1
      ),
      _c(VDataTable, {
        attrs: {
          headers: _vm.headers,
          items: _vm.pendingLicences,
          search: _vm.search
        },
        on: { "click:row": _vm.onRowClick },
        scopedSlots: _vm._u(
          [
            {
              key: "item.creationDate",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm._v(
                    " " +
                      _vm._s(new Date(item.creationDate).toLocaleString()) +
                      " "
                  )
                ]
              }
            },
            {
              key: "item.type",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    VChip,
                    {
                      attrs: {
                        dense: "",
                        outlined: "",
                        color: _vm.getColor(item.type + "asdf4t4t")
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.capitalizeStr(item.type)) + " ")]
                  )
                ]
              }
            }
          ],
          null,
          true
        )
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }