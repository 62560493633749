















































































































import HashColor from "@/plugins/hashcolor";
import Role from "@/types/Role";
import Vue from "vue";

export default Vue.extend({
  name: "App",

  data: () => ({
    tab: null,
    search: null,
    user: null,
    selectedRole: null,
    selectedEmail: null,
  }),

  computed: {
    roles() {
      return this.$store.getters.roles;
    },

    currentRole() {
      if (this.selectedRole)
        return this.$store.getters.roles.find(
          (role: Role) => role?.id === this.selectedRole
        );
      else return new Array<Role>();
    },

    loading() {
      return this.$store.getters.loading;
    },
  },

  methods: {
    addToRole() {
      this.$store.dispatch("addToRole", {
        userEmail: this.selectedEmail,
        roleId: this.selectedRole,
      });
    },

    removeFromRole(user: { id: string; name: string; email: string }) {
      if (
        confirm(
          `Are you sure you want to remove ${user.name} from ${this.selectedRole} role?`
        )
      )
        this.$store.dispatch("removeFromRole", {
          userId: user.id,
          roleId: this.selectedRole,
        });
    },

    getColor(what: string) {
      return HashColor.get(what ? what : "?");
    },

    nameInitials(name: string) {
      if (!name) return "?";
      const array = name.split(" ");
      let initials = "";

      array.forEach((element: string) => (initials += element.charAt(0)));

      if (initials.length === 0) {
        return "?";
      } else {
        return initials;
      }
    },
  },
  created() {
    this.$store.dispatch("fetchRoles");
  },
});
