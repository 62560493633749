import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VContainer,
    { attrs: { fluid: "" } },
    [
      _c(
        VRow,
        [
          _c(
            VCol,
            { staticClass: "user-data ma-3", attrs: { md: 3 } },
            [
              _vm.inspectUser && _vm.inspectAuth
                ? _c(
                    VCard,
                    { staticClass: "md-auto", attrs: { tile: "" } },
                    [
                      _c(
                        VCardTitle,
                        { staticClass: "justify-center" },
                        [
                          _c(
                            VAvatar,
                            {
                              staticClass: "mb-5",
                              attrs: {
                                color: _vm.getColor(_vm.inspectUser.email),
                                size: "85"
                              }
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "white--text headline" },
                                [_vm._v(_vm._s(_vm.inspectUserInitial))]
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(VCardSubtitle, { attrs: { align: "center" } }, [
                        _c("span", { staticClass: "white--text headline" }, [
                          _vm._v(_vm._s(_vm.inspectUser.name))
                        ])
                      ]),
                      _c(VDivider, { staticClass: "ml-15 mr-15" }),
                      _c(
                        VList,
                        [
                          _c(
                            VListItemGroup,
                            [
                              _c(
                                VListItem,
                                { attrs: { inactive: "" } },
                                [
                                  _c(
                                    VListItemIcon,
                                    [
                                      _c(VIcon, [
                                        _vm._v("mdi-database-outline")
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    VListItemContent,
                                    [
                                      _c(VListItemTitle, [
                                        _vm._v(_vm._s(_vm.inspectUser.id))
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    VListItemAction,
                                    [
                                      _c(
                                        VBtn,
                                        {
                                          directives: [
                                            {
                                              name: "clipboard",
                                              rawName: "v-clipboard:copy",
                                              value: _vm.inspectUser.id,
                                              expression: "inspectUser.id",
                                              arg: "copy"
                                            }
                                          ],
                                          attrs: { icon: "" }
                                        },
                                        [
                                          _c(VIcon, [
                                            _vm._v("mdi-content-copy")
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                VListItem,
                                { attrs: { inactive: "" } },
                                [
                                  _c(
                                    VListItemIcon,
                                    [
                                      _c(VIcon, [
                                        _vm._v("mdi-email-outline")
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    VListItemContent,
                                    [
                                      _c(VListItemTitle, [
                                        _vm._v(_vm._s(_vm.inspectUser.email))
                                      ]),
                                      _vm.inspectUser.emailVerified === false
                                        ? _c(VListItemSubtitle, [
                                            _c(
                                              "span",
                                              { staticClass: "warning--text" },
                                              [_vm._v("Email is not verified")]
                                            )
                                          ])
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _vm.inspectUser.emailVerified === false
                                    ? _c(
                                        VListItemAction,
                                        [
                                          _c(
                                            VBtn,
                                            {
                                              attrs: {
                                                small: "",
                                                outlined: "",
                                                color: "warning"
                                              }
                                            },
                                            [_vm._v(" Verify manually")]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    VListItemAction,
                                    [
                                      _c(
                                        VBtn,
                                        {
                                          directives: [
                                            {
                                              name: "clipboard",
                                              rawName: "v-clipboard:copy",
                                              value: _vm.inspectUser.email,
                                              expression: "inspectUser.email",
                                              arg: "copy"
                                            }
                                          ],
                                          attrs: { icon: "" }
                                        },
                                        [
                                          _c(VIcon, [
                                            _vm._v("mdi-content-copy")
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                VListItem,
                                { attrs: { inactive: "" } },
                                [
                                  _c(
                                    VListItemIcon,
                                    [
                                      _c(VIcon, [
                                        _vm._v(
                                          "mdi-account-arrow-right-outline"
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    VListItemContent,
                                    [
                                      _c(VListItemTitle, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              new Date(
                                                _vm.inspectAuth.created
                                              ).toLocaleString()
                                            ) +
                                            " "
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                VListItem,
                                { attrs: { inactive: "" } },
                                [
                                  _c(
                                    VListItemIcon,
                                    [
                                      _c(VIcon, [
                                        _vm._v("mdi-account-clock-outline")
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    VListItemContent,
                                    [
                                      _c(VListItemTitle, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              new Date(
                                                _vm.inspectAuth.lastSeen
                                              ).toLocaleString()
                                            ) +
                                            " "
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                VListItem,
                                { attrs: { inactive: "" } },
                                [
                                  _c(
                                    VListItemIcon,
                                    [
                                      _vm.isCollectData
                                        ? _c(VIcon, [_vm._v("mdi-check")])
                                        : _c(VIcon, [_vm._v("mdi-close")])
                                    ],
                                    1
                                  ),
                                  _c(
                                    VListItemContent,
                                    [
                                      _c(VListItemTitle, [
                                        _vm._v("Data collection")
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                VListItem,
                                { attrs: { inactive: "" } },
                                [
                                  _c(
                                    VListItemIcon,
                                    [
                                      _vm.isNewsletter
                                        ? _c(VIcon, [_vm._v("mdi-check")])
                                        : _c(VIcon, [_vm._v("mdi-close")])
                                    ],
                                    1
                                  ),
                                  _c(
                                    VListItemContent,
                                    [
                                      _c(VListItemTitle, [
                                        _vm._v("Newsletter")
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm.inspectUser.interest
                                ? _c(
                                    VListItem,
                                    { attrs: { inactive: "" } },
                                    [
                                      _c(
                                        VListItemIcon,
                                        [
                                          _c(VIcon, [
                                            _vm._v("mdi-account-heart-outline")
                                          ])
                                        ],
                                        1
                                      ),
                                      _c(
                                        VListItemContent,
                                        [
                                          _c(VListItemTitle, [
                                            _vm._v(
                                              _vm._s(_vm.inspectUser.interest)
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.inspectUser.contact.phoneNumber
                                ? _c(
                                    VListItem,
                                    { attrs: { inactive: "" } },
                                    [
                                      _c(
                                        VListItemIcon,
                                        [
                                          _c(VIcon, [
                                            _vm._v("mdi-phone-classic")
                                          ])
                                        ],
                                        1
                                      ),
                                      _c(
                                        VListItemContent,
                                        [
                                          _c(VListItemTitle, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.inspectUser.contact
                                                  .phoneNumber
                                              )
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VExpansionPanels,
                        { attrs: { accordion: "" } },
                        [
                          _c(
                            VExpansionPanel,
                            [
                              _c(VExpansionPanelHeader, [
                                _vm._v(" Location data ")
                              ]),
                              _c(
                                VExpansionPanelContent,
                                [
                                  _c(
                                    VList,
                                    { attrs: { dense: "" } },
                                    [
                                      _vm.inspectUser.location.country
                                        ? _c(
                                            VListItem,
                                            [
                                              _c(
                                                VListItemIcon,
                                                [
                                                  _c(VIcon, [
                                                    _vm._v(
                                                      "mdi-map-marker-outline"
                                                    )
                                                  ])
                                                ],
                                                1
                                              ),
                                              _c(
                                                VListItemContent,
                                                [
                                                  _c(VListItemTitle, [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.inspectUser.location
                                                          .country
                                                      )
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.inspectUser.location.town ||
                                      _vm.inspectUser.location.town
                                        ? _c(
                                            VListItem,
                                            [
                                              _c(
                                                VListItemIcon,
                                                [
                                                  _c(VIcon, [
                                                    _vm._v("mdi-city")
                                                  ])
                                                ],
                                                1
                                              ),
                                              _c(
                                                VListItemContent,
                                                [
                                                  _c(VListItemTitle, [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.inspectUser.location
                                                          .zipcode +
                                                          " " +
                                                          _vm.inspectUser
                                                            .location.town
                                                      )
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.inspectUser.location.street ||
                                      _vm.inspectUser.location.streetNumber
                                        ? _c(
                                            VListItem,
                                            [
                                              _c(
                                                VListItemIcon,
                                                [
                                                  _c(VIcon, [
                                                    _vm._v(
                                                      "mdi-home-search-outline"
                                                    )
                                                  ])
                                                ],
                                                1
                                              ),
                                              _c(
                                                VListItemContent,
                                                [
                                                  _c(VListItemTitle, [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.inspectUser.location
                                                          .street +
                                                          " " +
                                                          _vm.inspectUser
                                                            .location
                                                            .streetNumber
                                                      )
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            VExpansionPanel,
                            [
                              _c(VExpansionPanelHeader, [
                                _vm._v(" Company data ")
                              ]),
                              _c(
                                VExpansionPanelContent,
                                [
                                  _c(
                                    VList,
                                    { attrs: { dense: "" } },
                                    [
                                      _vm.inspectUser.company.name
                                        ? _c(
                                            VListItem,
                                            [
                                              _c(
                                                VListItemIcon,
                                                [
                                                  _c(VIcon, [
                                                    _vm._v("mdi-tag-outline")
                                                  ])
                                                ],
                                                1
                                              ),
                                              _c(
                                                VListItemContent,
                                                [
                                                  _c(VListItemTitle, [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.inspectUser.company
                                                          .name
                                                      )
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.inspectUser.company.sector
                                        ? _c(
                                            VListItem,
                                            [
                                              _c(
                                                VListItemIcon,
                                                [
                                                  _c(VIcon, [
                                                    _vm._v("mdi-ticket-outline")
                                                  ])
                                                ],
                                                1
                                              ),
                                              _c(
                                                VListItemContent,
                                                [
                                                  _c(VListItemTitle, [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.inspectUser.company
                                                          .sector
                                                      )
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.inspectUser.company.position
                                        ? _c(
                                            VListItem,
                                            [
                                              _c(
                                                VListItemIcon,
                                                [
                                                  _c(VIcon, [
                                                    _vm._v(
                                                      "mdi-account-question-outline"
                                                    )
                                                  ])
                                                ],
                                                1
                                              ),
                                              _c(
                                                VListItemContent,
                                                [
                                                  _c(VListItemTitle, [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.inspectUser.company
                                                          .position
                                                      )
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.inspectUser.company.size
                                        ? _c(
                                            VListItem,
                                            [
                                              _c(
                                                VListItemIcon,
                                                [
                                                  _c(VIcon, [
                                                    _vm._v(
                                                      "mdi-account-group-outline"
                                                    )
                                                  ])
                                                ],
                                                1
                                              ),
                                              _c(
                                                VListItemContent,
                                                [
                                                  _c(VListItemTitle, [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.inspectUser.company
                                                          .size
                                                      )
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.inspectUser.company.url
                                        ? _c(
                                            VListItem,
                                            [
                                              _c(
                                                VListItemIcon,
                                                [
                                                  _c(VIcon, [
                                                    _vm._v("mdi-link")
                                                  ])
                                                ],
                                                1
                                              ),
                                              _c(
                                                VListItemContent,
                                                [
                                                  _c(VListItemTitle, [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.inspectUser.company
                                                          .url
                                                      )
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _c(
                                            VListItem,
                                            [
                                              _c(
                                                VListItemIcon,
                                                [
                                                  _c(VIcon, [
                                                    _vm._v(
                                                      "mdi-google-downasaur"
                                                    )
                                                  ])
                                                ],
                                                1
                                              ),
                                              _c(VListItemTitle, [
                                                _vm._v(
                                                  " No company information "
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            VExpansionPanel,
                            [
                              _c(VExpansionPanelHeader, [
                                _vm._v(" University information ")
                              ]),
                              _c(
                                VExpansionPanelContent,
                                [
                                  _c(
                                    VList,
                                    { attrs: { dense: "" } },
                                    [
                                      _vm.inspectUser.company.name
                                        ? _c(
                                            VListItem,
                                            [
                                              _c(
                                                VListItemIcon,
                                                [
                                                  _c(VIcon, [
                                                    _vm._v("mdi-tag-outline")
                                                  ])
                                                ],
                                                1
                                              ),
                                              _c(
                                                VListItemContent,
                                                [
                                                  _c(VListItemTitle, [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.inspectUser
                                                          .university.name
                                                      )
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { attrs: { align: "center", justify: "center" } },
                [
                  _c(
                    VBtn,
                    {
                      staticClass: "mt-5",
                      attrs: {
                        disabled: "",
                        outlined: "",
                        large: "",
                        block: "",
                        color: "info"
                      }
                    },
                    [_vm._v("Send email address verification email")]
                  ),
                  _c(
                    VBtn,
                    {
                      staticClass: "mt-5",
                      attrs: {
                        disabled: "",
                        outlined: "",
                        large: "",
                        block: "",
                        color: "info"
                      }
                    },
                    [_vm._v("Send password reset email")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            VCol,
            { staticClass: "mt-3" },
            [
              _c(
                VTabs,
                {
                  attrs: { centered: "" },
                  model: {
                    value: _vm.tab,
                    callback: function($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab"
                  }
                },
                [
                  _c(VTabsSlider),
                  _c(VTab, { attrs: { href: "#licences" } }, [
                    _vm._v(" Licences ")
                  ]),
                  _c(VTab, { attrs: { href: "#memberships" } }, [
                    _vm._v(" Memberhips ")
                  ]),
                  _c(VTab, { attrs: { href: "#eventlog" } }, [
                    _vm._v(" Event Log ")
                  ])
                ],
                1
              ),
              _c(
                VTabsItems,
                {
                  attrs: { align: "center" },
                  model: {
                    value: _vm.tab,
                    callback: function($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab"
                  }
                },
                [
                  _c(
                    VTabItem,
                    { attrs: { value: "licences" } },
                    [
                      _c(
                        VCard,
                        { staticClass: "ma-5", attrs: { outlined: "" } },
                        [
                          _c("add-licence-modal", { staticClass: "ma-2" }),
                          _c(
                            VList,
                            {
                              attrs: {
                                "two-line": "",
                                "max-width": "600",
                                align: "left"
                              }
                            },
                            [
                              _vm._l(_vm.licences, function(lic, index) {
                                return [
                                  index > 0
                                    ? _c(VDivider, {
                                        key: index,
                                        attrs: { inset: "" }
                                      })
                                    : _vm._e(),
                                  _c(
                                    VListItem,
                                    {
                                      key: lic.id,
                                      attrs: {
                                        to:
                                          "/user/" +
                                          _vm.inspectUser.id +
                                          "/licence/" +
                                          lic.id,
                                        justify: "start",
                                        align: "left"
                                      }
                                    },
                                    [
                                      _c(
                                        VListItemAvatar,
                                        [
                                          lic.productName === "Consteel Starter"
                                            ? _c(VImg, {
                                                attrs: {
                                                  "max-height": "35",
                                                  contain: "",
                                                  alt: "Consteel starter",
                                                  src: require("@/assets/starter.svg")
                                                }
                                              })
                                            : lic.productName ===
                                                "Consteel Full" ||
                                              lic.productname === "Consteel"
                                            ? _c(VImg, {
                                                attrs: {
                                                  "max-height": "35",
                                                  contain: "",
                                                  alt: "Consteel",
                                                  src: require("@/assets/cs.svg")
                                                }
                                              })
                                            : lic.productName === "csJoint"
                                            ? _c(VImg, {
                                                attrs: {
                                                  contain: "",
                                                  "max-height": "35",
                                                  alt: "Consteel",
                                                  src: require("@/assets/joint.svg")
                                                }
                                              })
                                            : _vm._e()
                                        ],
                                        1
                                      ),
                                      _c(
                                        VListItemContent,
                                        [
                                          _c(VListItemTitle, [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.capitalizeStr(lic.type)
                                                ) +
                                                " licence "
                                            )
                                          ]),
                                          _c(VListItemSubtitle, [
                                            _vm._v(
                                              " " +
                                                _vm._s(lic.productName) +
                                                " "
                                            )
                                          ])
                                        ],
                                        1
                                      ),
                                      _c(
                                        VListItemAction,
                                        [
                                          lic.licenceStatus === "active"
                                            ? _c(
                                                VChip,
                                                {
                                                  attrs: {
                                                    outlined: "",
                                                    dense: "",
                                                    color: "success"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.capitalizeStr(
                                                          lic.licenceStatus
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            : lic.licenceStatus === "pending"
                                            ? _c(
                                                VChip,
                                                {
                                                  attrs: {
                                                    outlined: "",
                                                    color: "warning"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.capitalizeStr(
                                                          lic.licenceStatus
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            : _c(
                                                VChip,
                                                {
                                                  attrs: {
                                                    outlined: "",
                                                    color: "error"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        lic.licenceStatus
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              })
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    VTabItem,
                    { attrs: { value: "memberships" } },
                    [
                      _c(
                        VCard,
                        { staticClass: "ma-5", attrs: { outlined: "" } },
                        [
                          _c("add-membership-modal", { staticClass: "ma-5" }),
                          _c(
                            VList,
                            {
                              attrs: {
                                "two-line": "",
                                "max-width": "600",
                                align: "left"
                              }
                            },
                            [
                              _vm._l(_vm.memberships, function(
                                membership,
                                index
                              ) {
                                return [
                                  index > 0
                                    ? _c(VDivider, {
                                        key: index,
                                        attrs: { inset: "" }
                                      })
                                    : _vm._e(),
                                  _c(
                                    VListItem,
                                    {
                                      key: membership.id,
                                      attrs: {
                                        to: "/membership/" + membership.id
                                      }
                                    },
                                    [
                                      _c(
                                        VListItemAvatar,
                                        [
                                          _c(
                                            VAvatar,
                                            {
                                              attrs: {
                                                color: _vm.getColor(
                                                  membership.type
                                                )
                                              }
                                            },
                                            [
                                              membership.type === "basic"
                                                ? _c(VIcon, [
                                                    _vm._v(" mdi-chevron-up ")
                                                  ])
                                                : _vm._e(),
                                              membership.type === "pro"
                                                ? _c(VIcon, [
                                                    _vm._v(
                                                      " mdi-chevron-double-up "
                                                    )
                                                  ])
                                                : _vm._e(),
                                              membership.type === "premium"
                                                ? _c(VIcon, [
                                                    _vm._v(
                                                      " mdi-chevron-triple-up "
                                                    )
                                                  ])
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        VListItemContent,
                                        [
                                          _c(VListItemTitle, [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.capitalizeStr(
                                                    membership.type
                                                  )
                                                ) +
                                                " membership "
                                            )
                                          ]),
                                          _c(VListItemSubtitle, [
                                            _vm._v(
                                              " created " +
                                                _vm._s(
                                                  new Date(
                                                    membership.creationDate
                                                  ).toLocaleDateString()
                                                ) +
                                                " "
                                            )
                                          ])
                                        ],
                                        1
                                      ),
                                      _c(
                                        VListItemAction,
                                        [
                                          _c(VHover, {
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var hover = ref.hover
                                                    return [
                                                      _vm.isExpired(
                                                        membership.expirationDate
                                                      )
                                                        ? _c(
                                                            VChip,
                                                            {
                                                              attrs: {
                                                                outlined: "",
                                                                color: "grey"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    hover
                                                                      ? new Date(
                                                                          membership.expirationDate
                                                                        ).toLocaleDateString()
                                                                      : "Expired"
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        : _vm.isExpireSoon(
                                                            membership.expirationDate
                                                          )
                                                        ? _c(
                                                            VChip,
                                                            {
                                                              attrs: {
                                                                outlined: "",
                                                                color: "warning"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    hover
                                                                      ? new Date(
                                                                          membership.expirationDate
                                                                        ).toLocaleDateString()
                                                                      : "Expires soon"
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        : _c(
                                                            VChip,
                                                            {
                                                              attrs: {
                                                                outlined: "",
                                                                color: "success"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    hover
                                                                      ? new Date(
                                                                          membership.expirationDate
                                                                        ).toLocaleDateString()
                                                                      : "Active"
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              })
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }