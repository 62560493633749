import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VHover } from 'vuetify/lib/components/VHover';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VContainer,
    { attrs: { fluid: "", "fill-height": "" } },
    [
      _c(VHover, {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var hover = ref.hover
              return [
                _c(
                  VRow,
                  { attrs: { align: "center", justify: "center" } },
                  [
                    _c(
                      VCol,
                      { attrs: { cols: 12, sm: "4" } },
                      [
                        _c(
                          VCard,
                          {
                            class: { "on-hover": hover },
                            attrs: {
                              outlined: "",
                              align: "center",
                              justify: "center",
                              to: "/",
                              elevation: hover ? 6 : 0
                            }
                          },
                          [
                            _c(
                              VCardTitle,
                              {
                                staticClass: "justify-center",
                                attrs: { align: "center" }
                              },
                              [
                                _c(VImg, {
                                  staticClass: "ma-2 d-none d-md-block",
                                  attrs: {
                                    "max-height": "200",
                                    "max-width": "200",
                                    contain: "",
                                    alt: "Consteel starter",
                                    src: require("@/assets/lime.svg")
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              VCardTitle,
                              {
                                staticClass: "justify-center",
                                attrs: { align: "center" }
                              },
                              [
                                _c("span", { staticClass: "text-h1" }, [
                                  _vm._v("404")
                                ])
                              ]
                            ),
                            _c(
                              VCardSubtitle,
                              {
                                staticClass: "justify-center",
                                attrs: { align: "center" }
                              },
                              [
                                _c("span", { staticClass: "text-h4" }, [
                                  _vm._v("Resource not found")
                                ])
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }