
































































































































































import Vue from "vue";

export default Vue.extend({
  name: "App",
  data: () => {
    return {
      search: "",
      sortBy: "buildDate",
      desc: true,

      headers: [
        {
          text: "Build Number",
          value: "buildNumber",
        },
        {
          text: "Version",
          value: "version",
        },
        {
          text: "Build Date",
          value: "buildDate",
        },
        {
          text: "Status",
          value: "status",
          align: "center",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "center",
        },
      ],
    };
  },

  computed: {
    installPackages: function () {
      return this.$store.getters.installPackages;
    },
  },

  methods: {
    onPublish(id: string) {
      if (confirm("Are you sure you want to publish?")) {
        this.$store.dispatch("publishInstallPackage", id);
      }
    },
    onUnPublish(id: string) {
      if (confirm("Are you sure you want to unpublish?")) {
        this.$store.dispatch("unPublishInstallPackage", id);
      }
    },
    onBeta(id: string) {
      if (confirm("Are you sure you want to mark as beta?")) {
        this.$store.dispatch("markInstallPackageAsBeta", id);
      }
    },
    onUnBeta(id: string) {
      if (confirm("Are you sure you want to remove beta flag?")) {
        this.$store.dispatch("unMarkInstallPackageAsBeta", id);
      }
    },
  },
});
