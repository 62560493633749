

































import HashColor from "@/plugins/hashcolor";
import Vue from "vue";

export default Vue.extend({
  name: "App",
  data: () => {
    return {
      search: "",
      headers: [
        {
          text: "User name",
          value: "ownerName",
        },
        {
          text: "User email",
          value: "ownerEmail",
        },
        {
          text: "Type",
          value: "type",
          align: "center",
        },
        {
          text: "Request date",
          value: "creationDate",
        },
      ],
    };
  },

  computed: {
    pendingLicences: function () {
      return this.$store.getters.pendingLicences;
    },
  },

  methods: {
    onRowClick(licence: any, row: any) {
      this.$router.push(`/user/${licence.ownerId}/licence/${licence.id}`);
    },
    getColor(what: string) {
      return HashColor.get(what ? what : "?");
    },
    capitalizeStr(str: string) {
      return str.charAt(0).toLocaleUpperCase() + str.slice(1);
    },
  },
});
