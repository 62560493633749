import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VCard,
    { staticClass: "ma-3" },
    [
      _c(
        VCardTitle,
        [
          _vm._v(" Install packages "),
          _c(VSpacer),
          _c(VTextField, {
            attrs: {
              "append-icon": "mdi-magnify",
              label: "Search",
              "single-line": "",
              "hide-details": ""
            },
            model: {
              value: _vm.search,
              callback: function($$v) {
                _vm.search = $$v
              },
              expression: "search"
            }
          })
        ],
        1
      ),
      _c(VDataTable, {
        attrs: {
          headers: _vm.headers,
          items: _vm.installPackages,
          search: _vm.search,
          "sort-by": _vm.sortBy,
          "sort-desc": _vm.desc
        },
        on: {
          "update:sortBy": function($event) {
            _vm.sortBy = $event
          },
          "update:sort-by": function($event) {
            _vm.sortBy = $event
          },
          "update:sortDesc": function($event) {
            _vm.desc = $event
          },
          "update:sort-desc": function($event) {
            _vm.desc = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "item.status",
            fn: function(ref) {
              var item = ref.item
              return [
                item.inProgress === true
                  ? _c(
                      VChip,
                      {
                        staticClass: "ma-2",
                        attrs: { outlined: "", color: "warning" }
                      },
                      [
                        _c(VProgressCircular, {
                          staticClass: "mr-1",
                          attrs: {
                            width: "2",
                            size: "24",
                            indeterminate: "",
                            color: "white"
                          }
                        }),
                        _vm._v(" Uploading ")
                      ],
                      1
                    )
                  : item.published === true
                  ? _c(
                      VChip,
                      {
                        staticClass: "ma-2",
                        attrs: { outlined: "", color: "success" }
                      },
                      [
                        _c(VIcon, { staticClass: "mr-2" }, [
                          _vm._v("mdi-cloud-check-outline")
                        ]),
                        _vm._v(" Published ")
                      ],
                      1
                    )
                  : item.published === false
                  ? _c(
                      VChip,
                      {
                        staticClass: "ma-2",
                        attrs: { outlined: "", color: "grey" }
                      },
                      [
                        _c(VIcon, { staticClass: "mr-2" }, [
                          _vm._v("mdi-cloud-lock-outline")
                        ]),
                        _vm._v(" Unpublished ")
                      ],
                      1
                    )
                  : _vm._e(),
                item.isBeta === true
                  ? _c(
                      VChip,
                      {
                        staticClass: "ma-2",
                        attrs: { outlined: "", color: "purple" }
                      },
                      [
                        _c(VIcon, { staticClass: "mr-2" }, [
                          _vm._v("mdi-test-tube")
                        ]),
                        _vm._v(" Beta ")
                      ],
                      1
                    )
                  : _vm._e()
              ]
            }
          },
          {
            key: "item.actions",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  VMenu,
                  {
                    attrs: {
                      transition: "slide-y-transition",
                      "close-on-content-click": false,
                      bottom: ""
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var attrs = ref.attrs
                            var on = ref.on
                            return [
                              _c(
                                VBtn,
                                _vm._g(
                                  _vm._b(
                                    { attrs: { icon: "" } },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [_c(VIcon, [_vm._v("mdi-dots-vertical")])],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  },
                  [
                    _c(
                      VList,
                      { attrs: { nav: "", dense: "" } },
                      [
                        item.inProgress === false && item.published === false
                          ? _c(
                              VListItem,
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.onPublish(item.id)
                                  }
                                }
                              },
                              [
                                _c(
                                  VListItemIcon,
                                  [
                                    _c(VIcon, [
                                      _vm._v(" mdi-cloud-check-outline ")
                                    ])
                                  ],
                                  1
                                ),
                                _c(VListItemTitle, [_vm._v(" Publish ")])
                              ],
                              1
                            )
                          : _vm._e(),
                        item.inProgress === false && item.published === true
                          ? _c(
                              VListItem,
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.onUnPublish(item.id)
                                  }
                                }
                              },
                              [
                                _c(
                                  VListItemIcon,
                                  [
                                    _c(VIcon, [
                                      _vm._v(" mdi-cloud-lock-outline ")
                                    ])
                                  ],
                                  1
                                ),
                                _c(VListItemTitle, [_vm._v(" Unpublish ")])
                              ],
                              1
                            )
                          : _vm._e(),
                        !item.isBeta
                          ? _c(
                              VListItem,
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.onBeta(item.id)
                                  }
                                }
                              },
                              [
                                _c(
                                  VListItemIcon,
                                  [_c(VIcon, [_vm._v(" mdi-test-tube ")])],
                                  1
                                ),
                                _c(VListItemTitle, [
                                  _vm._v(" Mark as beta ")
                                ])
                              ],
                              1
                            )
                          : _vm._e(),
                        item.isBeta
                          ? _c(
                              VListItem,
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.onUnBeta(item.id)
                                  }
                                }
                              },
                              [
                                _c(
                                  VListItemIcon,
                                  [
                                    _c(VIcon, [
                                      _vm._v(" mdi-test-tube-off ")
                                    ])
                                  ],
                                  1
                                ),
                                _c(VListItemTitle, [
                                  _vm._v(" Remove beta flag ")
                                ])
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(VDivider),
                        item.inProgress === false
                          ? _c(
                              VListItem,
                              { attrs: { href: item.consteelUrl } },
                              [
                                _c(
                                  VListItemIcon,
                                  [
                                    _c(VIcon, [
                                      _vm._v(" mdi-download-outline ")
                                    ])
                                  ],
                                  1
                                ),
                                _c(VListItemTitle, [
                                  _vm._v(" Download Consteel ")
                                ])
                              ],
                              1
                            )
                          : _vm._e(),
                        item.inProgress === false
                          ? _c(
                              VListItem,
                              { attrs: { href: item.jointUrl } },
                              [
                                _c(
                                  VListItemIcon,
                                  [
                                    _c(VIcon, [
                                      _vm._v(" mdi-download-outline ")
                                    ])
                                  ],
                                  1
                                ),
                                _c(VListItemTitle, [
                                  _vm._v(" Download Joint ")
                                ])
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            }
          },
          {
            key: "item.buildDate",
            fn: function(ref) {
              var item = ref.item
              return [
                _vm._v(
                  " " + _vm._s(new Date(item.buildDate).toLocaleString()) + " "
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }