import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.inspectMembership
    ? _c(
        VContainer,
        { attrs: { fluid: "" } },
        [
          _c(
            VRow,
            [
              _c(
                VCol,
                { staticClass: "user-data ml-3 mt-3", attrs: { md: 3 } },
                [
                  _c(
                    VCard,
                    { staticClass: "md-auto", attrs: { tile: "" } },
                    [
                      _c(
                        VCardTitle,
                        { staticClass: "justify-center" },
                        [
                          _c(
                            VAvatar,
                            {
                              staticClass: "mb-5",
                              attrs: {
                                color: _vm.getColor(_vm.inspectMembership.type),
                                size: "85"
                              }
                            },
                            [
                              _vm.inspectMembership.type === "basic"
                                ? _c(VIcon, { attrs: { large: "" } }, [
                                    _vm._v("mdi-chevron-up")
                                  ])
                                : _vm._e(),
                              _vm.inspectMembership.type === "pro"
                                ? _c(VIcon, { attrs: { large: "" } }, [
                                    _vm._v("mdi-chevron-double-up")
                                  ])
                                : _vm._e(),
                              _vm.inspectMembership.type === "premium"
                                ? _c(VIcon, { attrs: { large: "" } }, [
                                    _vm._v("mdi-chevron-triple-up")
                                  ])
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VCardSubtitle,
                        { attrs: { align: "center" } },
                        [
                          _c(
                            VRow,
                            [
                              _c(VCol, { attrs: { cols: 12 } }, [
                                _c(
                                  "span",
                                  { staticClass: "white--text headline" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.capitalizeStr(
                                          _vm.inspectMembership.type
                                        )
                                      ) + " membership"
                                    )
                                  ]
                                )
                              ]),
                              _c(
                                VCol,
                                { attrs: { cols: 12 } },
                                [
                                  _vm.inspectMembership.userId
                                    ? _c(
                                        VChip,
                                        {
                                          attrs: {
                                            to:
                                              "/user/" +
                                              _vm.inspectMembership.userId,
                                            outlined: "",
                                            color: _vm.getColor(
                                              _vm.inspectMembership.ownerEmail
                                            )
                                          }
                                        },
                                        [
                                          _c(
                                            VIcon,
                                            { staticClass: "mr-3" },
                                            [
                                              _vm._v(
                                                " mdi-account-circle-outline "
                                              )
                                            ]
                                          ),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.inspectMembership.ownerName
                                              ) +
                                              " "
                                          )
                                        ],
                                        1
                                      )
                                    : _c(
                                        VChip,
                                        {
                                          attrs: {
                                            outlined: "",
                                            color: "warning"
                                          }
                                        },
                                        [
                                          _c(
                                            VIcon,
                                            { staticClass: "mr-3" },
                                            [
                                              _vm._v(
                                                " mdi-account-circle-outline "
                                              )
                                            ]
                                          ),
                                          _vm._v(
                                            " Does not belong to any user "
                                          )
                                        ],
                                        1
                                      )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(VDivider, { staticClass: "ml-15 mr-15" }),
                      _c(
                        VList,
                        [
                          _c(
                            VListItemGroup,
                            [
                              _c(
                                VListItem,
                                { attrs: { inactive: "" } },
                                [
                                  _c(
                                    VListItemIcon,
                                    [
                                      _c(VIcon, [
                                        _vm._v("mdi-database-outline")
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    VListItemContent,
                                    [
                                      _c(VListItemTitle, [
                                        _vm._v(_vm._s(_vm.inspectMembership.id))
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    VListItemAction,
                                    [
                                      _c(
                                        VBtn,
                                        {
                                          directives: [
                                            {
                                              name: "clipboard",
                                              rawName: "v-clipboard:copy",
                                              value: _vm.inspectMembership.id,
                                              expression:
                                                "inspectMembership.id",
                                              arg: "copy"
                                            }
                                          ],
                                          attrs: { icon: "" }
                                        },
                                        [
                                          _c(VIcon, [
                                            _vm._v("mdi-content-copy")
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                VListItem,
                                { attrs: { inactive: "" } },
                                [
                                  _c(
                                    VListItemIcon,
                                    [_c(VIcon, [_vm._v("mdi-resistor")])],
                                    1
                                  ),
                                  _c(
                                    VListItemContent,
                                    [
                                      _c(
                                        VListItemTitle,
                                        [
                                          _vm.isExpired
                                            ? _c(
                                                VChip,
                                                {
                                                  attrs: {
                                                    outlined: "",
                                                    color: "grey"
                                                  }
                                                },
                                                [_vm._v(" Expired ")]
                                              )
                                            : _vm.isExpireSoon
                                            ? _c(
                                                VChip,
                                                {
                                                  attrs: {
                                                    outlined: "",
                                                    color: "warning"
                                                  }
                                                },
                                                [_vm._v(" Expires soon ")]
                                              )
                                            : _c(
                                                VChip,
                                                {
                                                  attrs: {
                                                    outlined: "",
                                                    color: "success"
                                                  }
                                                },
                                                [_vm._v(" Active ")]
                                              )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                VListItem,
                                { attrs: { inactive: "" } },
                                [
                                  _c(
                                    VListItemIcon,
                                    [
                                      _c(VIcon, [
                                        _vm._v("mdi-wallet-membership")
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    VListItemContent,
                                    [
                                      _c(VListItemTitle, [
                                        _vm._v(
                                          _vm._s(_vm.inspectMembership.type)
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    VListItemAction,
                                    [
                                      _c(
                                        VDialog,
                                        {
                                          attrs: {
                                            "nudge-right": 40,
                                            transition: "scale-transition",
                                            "offset-y": "",
                                            width: "20%"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  return [
                                                    _c(
                                                      VBtn,
                                                      _vm._g(
                                                        { attrs: { icon: "" } },
                                                        on
                                                      ),
                                                      [
                                                        _c(VIcon, [
                                                          _vm._v(
                                                            "mdi-pencil-outline"
                                                          )
                                                        ])
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            3271518839
                                          ),
                                          model: {
                                            value: _vm.dialog,
                                            callback: function($$v) {
                                              _vm.dialog = $$v
                                            },
                                            expression: "dialog"
                                          }
                                        },
                                        [
                                          _c(
                                            VCard,
                                            [
                                              _c(VAutocomplete, {
                                                staticClass: "ma-3",
                                                attrs: {
                                                  outlined: "",
                                                  dense: "",
                                                  items: [
                                                    "basic",
                                                    "pro",
                                                    "premium"
                                                  ],
                                                  label: "Memberhsip type",
                                                  value:
                                                    _vm.inspectMembership.type
                                                },
                                                on: {
                                                  input:
                                                    _vm.modifyMembershipType
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                VListItem,
                                { attrs: { inactive: "" } },
                                [
                                  _c(
                                    VListItemIcon,
                                    [_c(VIcon, [_vm._v("mdi-history")])],
                                    1
                                  ),
                                  _c(
                                    VListItemContent,
                                    [
                                      _c(VListItemTitle, [
                                        _vm._v(
                                          _vm._s(
                                            new Date(
                                              _vm.inspectMembership.expirationDate
                                            ).toLocaleString()
                                          )
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    VListItemAction,
                                    [
                                      _c(
                                        VMenu,
                                        {
                                          attrs: {
                                            "close-on-content-click": true,
                                            "nudge-right": 40,
                                            transition: "scale-transition",
                                            "offset-y": "",
                                            "min-width": "290px"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  return [
                                                    _c(
                                                      VBtn,
                                                      _vm._g(
                                                        { attrs: { icon: "" } },
                                                        on
                                                      ),
                                                      [
                                                        _c(VIcon, [
                                                          _vm._v(
                                                            "mdi-pencil-outline"
                                                          )
                                                        ])
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            3271518839
                                          )
                                        },
                                        [
                                          _c(VDatePicker, {
                                            attrs: { small: "" },
                                            on: {
                                              input: function($event) {
                                                _vm.modifyDate()
                                                _vm.menu = false
                                              }
                                            },
                                            model: {
                                              value: _vm.date,
                                              callback: function($$v) {
                                                _vm.date = $$v
                                              },
                                              expression: "date"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { attrs: { align: "center", justify: "center" } },
                    [
                      _c(
                        VBtn,
                        {
                          staticClass: "mt-5",
                          attrs: { outlined: "", block: "", color: "error" },
                          on: { click: _vm.deleteMembership }
                        },
                        [_vm._v("Delete")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                VCol,
                { staticClass: "mt-3" },
                [
                  _c(
                    VTabs,
                    {
                      attrs: { centered: "" },
                      model: {
                        value: _vm.tab,
                        callback: function($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab"
                      }
                    },
                    [
                      _c(VTabsSlider),
                      _c(VTab, { attrs: { href: "#eventlog" } }, [
                        _vm._v(" Event Log ")
                      ])
                    ],
                    1
                  ),
                  _c(
                    VTabsItems,
                    {
                      attrs: { align: "center" },
                      model: {
                        value: _vm.tab,
                        callback: function($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab"
                      }
                    },
                    [
                      _c(
                        VTabItem,
                        { attrs: { value: "eventlog" } },
                        [
                          _c(VCard, {
                            staticClass: "ma-5",
                            attrs: { outlined: "" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }