






























































































































































































import Vue from "vue";
import HashColor from "@/plugins/hashcolor";

export default Vue.extend({
  components: {},
  name: "App",
  data: () => ({
    tab: null,
    date: "",
    dialog: false,
  }),

  computed: {
    inspectMembership() {
      return this.$store.getters.inspectMembership;
    },

    isExpired() {
      return this.$store.getters.inspectMembership.expirationDate < Date.now();
    },

    isExpireSoon() {
      const diff =
        this.$store.getters.inspectMembership.expirationDate - Date.now();

      return diff > 0 && diff < 7 * 24 * 60 * 60 * 1000;
    },
  },

  methods: {
    getColor(what: string) {
      return HashColor.get(what);
    },

    capitalizeStr(str: string) {
      return str.charAt(0).toLocaleUpperCase() + str.slice(1);
    },

    modifyDate() {
      const membership = JSON.parse(
        JSON.stringify(this.$store.getters.inspectMembership)
      );

      delete membership["ownerEmail"];
      delete membership["ownerName"];

      membership.expirationDate = new Date(this.date).getTime();
      membership.muexpirationDate = new Date(this.date).getTime();
      this.$store.dispatch("modifyMembership", membership);
    },

    modifyMembershipType(membershipType: string) {
      const membership = JSON.parse(
        JSON.stringify(this.$store.getters.inspectMembership)
      );

      delete membership["ownerEmail"];
      delete membership["ownerName"];

      if (membership.type != membershipType) {
        membership.type = membershipType;
        this.$store.dispatch("modifyMembership", membership);
        this.dialog = false;
      }
    },

    deleteMembership() {
      const membership = this.$store.getters.inspectMembership;

      if (membership.accessId === "") {
        if (confirm("Are you sure you want to delete this membership?")) {
          const userId = membership.userId;
          this.$store.dispatch("deleteUserMembership", membership.id);
          this.$router.push(`/user/${userId}`);
        }
      } else {
        this.$store.dispatch(
          "setError",
          "Cannot delete membership if it has Access ID!"
        );
      }
    },
  },

  watch: {
    $route(to, from) {
      if (to.params.membershipId !== from.params.membershipId) {
        this.$store.dispatch(
          "fetchInspectMembership",
          this.$route.params.membershipId
        );
      }
    },
  },

  created() {
    this.$store.dispatch(
      "fetchInspectMembership",
      this.$route.params.membershipId
    );
  },
});
